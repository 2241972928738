/* DON'T EDIT THIS FILE: edit original and run build again */ import { useCurrentTenant } from "../../framework/app-state/app-state-context.ts";
import { AdminTenantSelect } from "../../framework/auth-admin-tenant-select/admin-tenant-select.tsx";
import { UserProfile } from "../../framework/auth-profile/schema.ts";
import { useRequiredActionsCached } from "../../framework/auth-required-actions-context/use-required-actions.front.ts";
import {
  useFirebaseAccount,
  useProfile,
  useRolesMap,
} from "../../framework/auth-ui/use-account.ts";
import { canAccessTenant } from "../../framework/auth/allowed-tenants.ts";
import { isEmptyObject } from "../../framework/core/is-empty-object.ts";
import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { ascSorter } from "../../framework/core/sort-by-many.ts";
import { combinePipesAsObject } from "../../framework/data-pipe/combine-pipes-as-object.ts";
import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { usePipe } from "../../framework/firebase/use-pipe.ts";
// @ts-expect-error the hook is auto generated
import collectedMenuItems from "../../hook/navbar-collect.js";
import { RolesMap } from "../../framework/permission/schema.ts";
import {
  SidebarMenuItem,
  SidebarMenuItemContext,
} from "../../framework/sidebar-type/sidebar-types.ts";
import { SidebarMenuOption } from "../../framework/sidebar/menu-option.tsx";
import { SignOutButton } from "../../framework/sidebar/sign-out.tsx";
import { UserInfo } from "../../framework/sidebar/user-info.tsx";
import { getTenantHandler } from "../../framework/tenant-collection/tenant.ts";
import { TenantHandler } from "../../framework/tenant-handler-type/tenant-handler.ts";
import { GenericIconProps } from "../../framework/theme-icons/generic-icon.tsx";
import { isUserUsingMobile } from "../../credit/ui/user-in-mobile.ts";
import { useEffect, useState } from "react";

const buildMenuItems = (): SidebarMenuItem[] => {
  const menuItems = [...collectedMenuItems];
  menuItems.sort(ascSorter((item) => item.order));
  return menuItems;
};

let menuOptions: SidebarMenuItem[] | undefined;

const getMenuItems = ({
  roles,
  profile,
  tenant,
  tenantHandler,
}: {
  roles: RolesMap;
  profile: UserProfile;
  tenant: string;
  tenantHandler: TenantHandler;
}): SidebarMenuItem[] => {
  if (!menuOptions) {
    menuOptions = buildMenuItems();
  }
  const filteredMenuOptions = menuOptions.filter(({ condition }) =>
    condition({ roles, profile, tenant, tenantHandler })
  );
  return filteredMenuOptions;
};

const createNotificationsPipe = (
  dropDownItems: SidebarMenuItem[],
  props: SidebarMenuItemContext
) =>
  combinePipesAsObject(
    mapArrayToObject(dropDownItems, (item) => {
      if (item.getHasNotificationsPipe) {
        return [item.id, item.getHasNotificationsPipe(props)];
      }
      return [
        item.id,
        DataPipe.withInitialData(null, { unread: 0, badge: null }),
      ];
    })
  );

const SideBarLogo = ({ tenant }: { tenant: string }) => {
  const [SideBarLogoComponent, setSideBarLogoComponent] =
    useState<React.ComponentType<GenericIconProps> | null>(null);
  useEffect(() => {
    getTenantHandler(tenant)
      .getSidebarPageSmallLogo()
      .then((component) => {
        setSideBarLogoComponent(() => component);
      });
  }, [tenant]);
  if (SideBarLogoComponent) {
    return <SideBarLogoComponent className="icon-2" />;
  } else {
    return null;
  }
};

export const Sidebar = ({ current }: { current?: string }): React.ReactNode => {
  const account = useFirebaseAccount();
  const roles = useRolesMap();
  const profile = useProfile();
  const tenant = useCurrentTenant();
  const tenantHandler = getTenantHandler(tenant);
  const isMobile = isUserUsingMobile();
  const menuItems =
    account && canAccessTenant(profile, tenant)
      ? getMenuItems({
          roles,
          profile,
          tenant,
          tenantHandler,
        })
      : [];
  const primaryItems = menuItems.filter((x) => {
    return x.hasPriority;
  });
  const secondaryItems = menuItems.filter((x) => {
    return !x.hasPriority;
  });
  const [loadingNotifications, notificationData] = usePipe(
    createNotificationsPipe,
    [menuItems, { tenant, tenantHandler, roles, profile }]
  );
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const open = focused || hovered;
  const { hasRequiredActions } = useRequiredActionsCached();
  if (hasRequiredActions || isEmptyObject(profile) || isEmptyObject(roles)) {
    return null;
  }
  return (
    <div style={{ width: "58px" }}>
      <div
        className={
          "bg-gray20 sidebar d-flex flex-column justify-content-between p-2 " +
          (open ? "sidebar-expanded" : "sidebar-collapsed")
        }
        tabIndex={0}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          overflowY: isMobile ? "scroll" : "hidden",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div>
          <SidebarMenuOption
            icon={() => <SideBarLogo tenant={tenant} />}
            path={"/"}
            label={"Matchfin"}
            showLabel={false}
            showHover={false}
          />
          {primaryItems.map(({ id, getPath, label, icon }) => (
            <SidebarMenuOption
              path={getPath({ roles })}
              label={label}
              icon={icon}
              notificationData={
                !loadingNotifications && notificationData
                  ? notificationData[id]
                  : null
              }
              sidebarOpen={open}
              selected={current === id}
              key={id}
            />
          ))}
          {!isEmptyObject(secondaryItems) && (
            <hr className="mt-3 mb-3 border border-gray50 border-1 w-100" />
          )}
          {secondaryItems.map(({ id, getPath, label, icon }) => (
            <SidebarMenuOption
              path={getPath({ roles })}
              label={label}
              icon={icon}
              notificationData={
                current !== id && !loadingNotifications && notificationData
                  ? notificationData[id]
                  : null
              }
              selected={current === id}
              sidebarOpen={open}
              key={id}
            />
          ))}
        </div>
        {profile && profile.email ? (
          <div>
            <UserInfo profile={profile} open={open} />
            <div
              className="d-flex flex-row"
              style={open ? {} : { visibility: "hidden" }}
            >
              <AdminTenantSelect />
              <SignOutButton />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
