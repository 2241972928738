/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseFolder } from "../../credit/base-folder/base-folder-type.ts";
import { notNull } from "../../framework/core/not-null.ts";
import { PasswordInput } from "../../credit/live-form-core-types/password/password-input.tsx";
import { LiveField } from "../../credit/live-form/live-field.ts";
import {
  LiveFormRecordValue,
  LiveFormSpecificFolder,
} from "../../credit/live-form/types.ts";
import {
  LiveFieldInputParam,
  LiveFieldRenderer,
} from "../../credit/live-form/ui/types.ts";
import { Div } from "../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { HelpModal } from "../../framework/theme/help-modal.tsx";
import { useDelayedChangeTextInputState } from "../../credit/ui/form/delayed-change-text-input.tsx";
import { ValidationFeedback } from "../../credit/ui/form/validation-feedback.tsx";
import { useEffect, useState } from "react";
import { PasswordSetterLiveField } from "./live-field.ts";
import { getPasswordScoreAsync } from "./score.ts";
import { passwordSetterLiveFieldType } from "./type.ts";

const useScorer = ({
  value,
  recordValue,
  baseFolder,
  specificFolder,
  disallow,
  scorers,
  context,
}: {
  value: any;
  recordValue?: LiveFormRecordValue;
  baseFolder?: BaseFolder;
  specificFolder?: LiveFormSpecificFolder;
  disallow: any;
  scorers: any;
  context: LiveField[];
}) => {
  const [result, setResult] = useState({ score: null, feedback: null });
  useEffect(() => {
    getPasswordScoreAsync({
      value,
      liveData: {
        recordValue,
        baseFolder,
        specificFolder,
      },
      disallow,
      scorers,
      context,
    }).then((response) => setResult(response));
  }, [
    value,
    recordValue,
    scorers,
    disallow,
    baseFolder,
    specificFolder,
    context,
  ]);
  return result;
};

const Score = ({ score }: { score: number | null }) => {
  switch (score) {
    case 0:
      return <span className="px-3 bg-warning text-white rounded">mala</span>;
    case 1:
      return <span className="px-3 bg-orange40 rounded">fácil</span>;
    case 2:
      return (
        <span className="px-3 bg-green40 bg-opacity-25 rounded">media</span>
      );
    case 3:
      return (
        <span className="px-3 bg-green40 bg-opacity-50 rounded">buena</span>
      );
    case 4:
      return <span className="px-3 bg-green40 rounded">excelente</span>;
    default:
      return <span className="px-3 bg-gray80 rounded">por evaluar</span>;
  }
};

const InfoPopUp = () => {
  return (
    <HelpModal>
      <Div className="text-l-compact fw-bold my-3">
        Qué es una contraseña segura
      </Div>
      <Div>
        Una contraseña segura es una secuencia de caracteres difícil de
        adivinar, tanto para otra persona como para un robot. Estos caracteres
        pueden incluir letras, números o cualquiera de los símbolos especiales
        de tu teclado, incluyendo espacios.
        <br />
        <br /> Para garantizar la seguridad del sitio y proteger tu cuenta,
        pedimos que todas las contraseñas sean seguras. A tal fin, las evaluamos
        antes de permitir su uso. Para establecer una contraseña segura, te
        sugerimos:
        <br /> <br />
      </Div>
      <ul>
        <li>
          Escribir en mayúsclas algunas letras, pero no todas, además de la
          primera.
        </li>
        <li> Usar otros símbolos además de letras. Por ejemplo, números.</li>
        <li> Escribir varias palabras, pero evitar frases comunes. </li>
        <li>
          Evitar palabras y fechas que estén asociadas contigo o con el sitio,
          al igual que años recientes.
        </li>
        <li> Evitar palabras y letras repetidas. </li>
        <li>
          Evitar secuencias y patrones de caracteres comunes, como 'abc' o las
          letras de tu teclado en el orden en que aparecen.
        </li>
        <li> Evitar palabras comunes escritas al revés. </li>
        <li> Evitar sustituciones predecibles. </li>
      </ul>
      <Div>
        Una vez que elijas una contraseña, la vamos a evaluar. Su seguridad debe
        ser al menos 'media' para poder ser utilizada.
      </Div>
    </HelpModal>
  );
};

const SecurityScorer = ({ score }: { score: number | null }) => {
  return (
    <div className="d-flex my-3 align-items-center justify-content-between">
      <span className="d-flex">
        <div className="me-2 fs-6">
          Seguridad de la contraseña <InfoPopUp />:
        </div>
        <Score score={score} />
      </span>
      <span className="mx-2 cursor-pointer text-decoration-underline tenant-color-primary">
        evaluar
      </span>
    </div>
  );
};

const PasswordDelayedInput = ({ liveCell, saveField }: LiveFieldInputParam) => {
  const value = liveCell.getValue();
  const { recordValue, baseFolder, specificFolder } = liveCell.getLiveData();
  const liveField = liveCell.getLiveField();
  if (!(liveField instanceof PasswordSetterLiveField)) {
    throw new Error(
      `Expected a PasswordSetterLiveField, but got ${liveField?.constructor?.name}`
    );
  }
  const { score, feedback } = useScorer({
    value,
    recordValue,
    baseFolder,
    specificFolder,
    disallow: liveField.getDisallow(),
    scorers: liveField.getScorers(),
    context: liveCell.getContext(),
  });
  const liveData = liveCell.getLiveData();
  const error = liveField.getRawTypeErrorFromValue(
    {
      value,
      score,
      feedback,
    },
    liveData,
    liveCell.getContext(),
    liveCell.getShowErrors()
  );
  const field = notNull(liveField.getField());
  return (
    <>
      <PasswordInput
        {...useDelayedChangeTextInputState({
          value: value as string,
          setValue: (value: string) => saveField(field, value),
        })}
        autoFocus={liveField.getAutoFocus()}
        className={error ? " is-invalid " : ""}
        disabled={liveCell.isDisabled()}
        autoComplete={liveField.getAutoComplete()}
      />
      <SecurityScorer score={score} />
      {error ? <ValidationFeedback error={error} /> : null}
    </>
  );
};

export const passwordSetterLiveFieldRenderer: LiveFieldRenderer = {
  type: passwordSetterLiveFieldType,
  Input: PasswordDelayedInput,
};
