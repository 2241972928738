/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseLiveField, LiveFieldSpec } from "../../credit/live-form/field-spec.ts";
import { LiveField } from "../../credit/live-form/live-field.ts";
import { LiveData } from "../../credit/live-form/types.ts";
import { isRequiredText } from "../../credit/ui/form/is-required.ts";
import { passwordSetterErrorMessages } from "./error-messages.ts";
import { getPasswordScore } from "./score.ts";
import { passwordSetterLiveFieldType } from "./type.ts";

type PasswordSetterLiveFieldSpec = LiveFieldSpec & {
  scorers?: any;
  disallow?: any;
  autoComplete?: string;
};

export class PasswordSetterLiveField extends BaseLiveField {
  private scorers: any;
  private disallow: any;
  private autoComplete: string;

  constructor(spec: PasswordSetterLiveFieldSpec) {
    super(spec, passwordSetterLiveFieldType);
    this.scorers = spec.scorers;
    this.disallow = spec.disallow;
    this.autoComplete = spec.autoComplete ?? "new-password";
  }

  isEmpty(liveData: LiveData) {
    return !this.getValue(liveData);
  }

  getTypeDefaultValue() {
    return "";
  }

  getTypeError(liveData: LiveData, context: LiveField[]) {
    return this.getRawTypeErrorFromValue(
      this.getValue(liveData),
      liveData,
      context,
      true
    );
  }

  getRawTypeErrorFromValue(
    value: any,
    liveData: LiveData,
    context: LiveField[],
    showRequired = false
  ) {
    const {
      value: current,
      score,
      feedback,
    }: any = value instanceof Object
      ? value
      : {
          value,
          ...getPasswordScore({
            value,
            context,
            liveData,
            scorers: this.getScorers(),
            disallow: this.getDisallow(),
          }),
        };
    if (feedback?.warning) {
      return passwordSetterErrorMessages[feedback.warning] + ".";
    } else if (current && score && score < 2) {
      return "la contraseña propuesta no es segura.";
    } else if (showRequired && this.isRequired(liveData) && !current) {
      // we repeat this check even though the parent class would handle it since this method is called directly from the renderer to be able to cache the value bypassing the parent's getError()
      return isRequiredText;
    }
    return null;
  }

  showsOwnErrors() {
    return true;
  }

  getScorers() {
    return this.scorers;
  }

  getDisallow() {
    return this.disallow;
  }

  getAutoComplete() {
    return this.autoComplete;
  }
}
