/* DON'T EDIT THIS FILE: edit original and run build again */ import { getRealmFromCurrentHostAlone } from "../../framework/realm/get-realm-from-current-host.ts";
import {
  getTenantCodeFromPathLabel,
  getTenantHandler,
} from "../../framework/tenant-collection/tenant.ts";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";

export const parseRealmFromUrl = () => {
  const realmFromHost = getRealmFromCurrentHostAlone();
  // allow r/realm only in matchfin domain
  if (realmFromHost === matchfinTenant) {
    const path = window.location.pathname;
    const regex = /^(\/r\/([a-z-]+))(\/|$)/;
    const match = regex.exec(path);
    // don't allow /r/matchfin/ in matchfin host
    if (match && match[2] !== getTenantHandler(matchfinTenant).getPathLabel()) {
      const realmFromPath = getTenantCodeFromPathLabel(match[2]);
      if (realmFromPath) {
        return [match[1], realmFromPath];
      }
    }
  }
  return ["", realmFromHost];
};
