/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserPrivateProfile, UserProfile } from "../../framework/auth-profile/schema.ts";
import { FirebaseAccount } from "../../framework/auth/firebase-account.ts";
import { notNull } from "../../framework/core/not-null.ts";
import { UserFirstTimePopups } from "../../framework/first-time-popups-data/data.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import React, { useContext } from "react";

type AccountContext = {
  profile: UserProfile;
  roles: RolesMap;
  privateProfile: UserPrivateProfile;
  account: FirebaseAccount;
  firstTimeModals: UserFirstTimePopups;
};

export const AccountContext = React.createContext<Partial<AccountContext>>({});

export const useAccountCached = () => useContext(AccountContext);

export const useProfile = (): UserProfile => {
  const { profile } = useContext(AccountContext);
  return notNull(profile);
};

export const useRolesMap = (): RolesMap => {
  const { roles } = useContext(AccountContext);
  return notNull(roles);
};

export const usePrivateProfile = (): UserPrivateProfile => {
  const { privateProfile } = useContext(AccountContext);
  return notNull(privateProfile);
};

export const useFirebaseAccount = (): FirebaseAccount => {
  const { account } = useContext(AccountContext);
  return notNull(account);
};

export const useFirstTimePopups = (): UserFirstTimePopups => {
  const { firstTimeModals } = useContext(AccountContext);
  return notNull(firstTimeModals);
};
