/* DON'T EDIT THIS FILE: edit original and run build again */ import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import {
  LogoMatchfinColoredIcon,
  TurnMobileIcon,
} from "../../framework/theme-icons/icon.ts";
import { useMediaQuery } from "../../credit/ui/use-media-query.ts";
import { useEffect, useState } from "react";

export const OrientationOverlay = ({
  realmFromUrl,
}: {
  realmFromUrl: string;
}) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const isHorizontal = useMediaQuery("(orientation: landscape)");
  const isMobile =
    /Mobi|Mobile/i.test(navigator.userAgent) &&
    !/iPad/i.test(navigator.userAgent);

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);
  return (
    isMobile &&
    !isHorizontal &&
    isPageLoaded && (
      <div
        style={{ zIndex: 9999, overflow: "auto" }}
        className={
          "d-print-none bg-gray20 d-flex justify-content-center align-items-center text-center overflow-y-scroll-hidden top-0 w-100 h-100 position-fixed"
        }
      >
        <div
          className={
            "text-l text-white d-flex flex-column justify-content-center align-items-center w-75"
          }
        >
          {realmFromUrl === matchfinTenant && (
            <LogoMatchfinColoredIcon
              style={{ width: "100%", height: "auto" }}
            />
          )}

          <p>
            Por favor <strong>gire su dispositivo</strong> para ver
            correctamente el <strong>contenido</strong>.
          </p>

          <TurnMobileIcon style={{ width: "80%", height: "auto" }} />
        </div>
      </div>
    )
  );
};
