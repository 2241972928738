/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  useCurrentTenant,
  useSettings,
} from "../../framework/app-state/app-state-context.ts";
import { RequiredActionType } from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { useAccountCached } from "../../framework/auth-ui/use-account.ts";
import { verifyConformanceToCurrentPolicyRequiredAction } from "../../framework/auth-verify-conformance/required-action-item.front.tsx";
import { verifyEmailRequiredAction } from "../../framework/auth-verify-email/required-action-item.front.tsx";
import { verifyTermsAndConditionsRequiredAction } from "../../framework/auth-verify-terms/required-action-item.front.tsx";
import { frozenEmptyObject } from "../../framework/core/frozen-empty-object.ts";
import { isEmptyObject } from "../../framework/core/is-empty-object.ts";
import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { pendingApprovalRole } from "../../framework/permission/all-roles.ts";
import { RolesMap } from "../../framework/permission/schema.ts";

const requiredActions: RequiredActionType[] = [
  verifyTermsAndConditionsRequiredAction,
  verifyEmailRequiredAction,
  verifyConformanceToCurrentPolicyRequiredAction,
];

export const getRequiredActionItems = (ids: any) =>
  requiredActions.filter((action) => ids[action.id]);

const isPendingApproval = (roles: RolesMap) => !!roles[pendingApprovalRole];

let requiredActionsFirstTime = true;

export const getRequiredActionIsFirstTime = () => {
  return requiredActionsFirstTime;
};

export const setRequiredActionsIsFirstTime = (state: any) => {
  requiredActionsFirstTime = state;
};

export const useRequiredActions = () => {
  const account = useAccountCached();
  const currentTenant = useCurrentTenant(true);
  const settings = useSettings();
  const doNotEnforce = isPendingApproval(account.roles ?? frozenEmptyObject); // do not enforce actions until user is approved.
  const out = doNotEnforce
    ? {}
    : mapArrayToObject(
        requiredActions.filter(
          (action: any) =>
            action.condition({ ...account, currentTenant }, settings) &&
            action.enforce({ ...account, currentTenant }, settings)
        ),
        (action) => [action.id, true]
      );
  const hasRequiredActions = !isEmptyObject(out);
  if (!hasRequiredActions) {
    setRequiredActionsIsFirstTime(true);
  }
  return {
    actions: out,
    hasRequiredActions,
  };
};
