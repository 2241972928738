// DON'T EDIT THIS FILE: edit the original Triggers.ts files and regenerate derived files

import hook0 from "../framework/tenant/acavalores/acavalores-tenant-handler.ts";
import hook1 from "../framework/tenant/acindar/acindar-tenant-handler.ts";
import hook2 from "../framework/tenant/adcap/adcap-tenant-handler.ts";
import hook3 from "../framework/tenant/affidavit/affidavit-tenant-handler.ts";
import hook4 from "../framework/tenant/alchemy/alchemy-tenant-handler.ts";
import hook5 from "../framework/tenant/aptorgan/aptorgan-tenant-handler.ts";
import hook6 from "../framework/tenant/argenfunds/argenfunds-tenant-handler.ts";
import hook7 from "../framework/tenant/argenpymes/argenpymes-tenant-handler.ts";
import hook8 from "../framework/tenant/argsecurities/argsecurities-tenant-handler.ts";
import hook9 from "../framework/tenant/avalfertil/avalfertil-tenant-handler.ts";
import hook10 from "../framework/tenant/avalrural/avalrural-tenant-handler.ts";
import hook11 from "../framework/tenant/avaluar/avaluar-tenant-handler.ts";
import hook12 from "../framework/tenant/awa/awa-tenant-handler.ts";
import hook13 from "../framework/tenant/bancobica/bancobica-tenant-handler.ts";
import hook14 from "../framework/tenant/bancocoinag/bancocoinag-tenant-handler.ts";
import hook15 from "../framework/tenant/bancocomafi/bancocomafi-tenant-handler.ts";
import hook16 from "../framework/tenant/bancocorrientes/bancocorrientes-tenant-handler.ts";
import hook17 from "../framework/tenant/bavsa/bavsa-tenant-handler.ts";
import hook18 from "../framework/tenant/brio/brio-tenant-handler.ts";
import hook19 from "../framework/tenant/casfog/casfog-tenant-handler.ts";
import hook20 from "../framework/tenant/cuyo-aval/cuyo-tenant-handler.ts";
import hook21 from "../framework/tenant/davalores/davalores-tenant-handler.ts";
import hook22 from "../framework/tenant/delta/delta-tenant-handler.ts";
import hook23 from "../framework/tenant/dracma/dracma-tenant-handler.ts";
import hook24 from "../framework/tenant/dracma-fci/dracma-fci-tenant-handler.ts";
import hook25 from "../framework/tenant/efire/efire-tenant-handler.ts";
import hook26 from "../framework/tenant/elyca/elyca-tenant-handler.ts";
import hook27 from "../framework/tenant/facimex/facimex-tenant-handler.ts";
import hook28 from "../framework/tenant/fidaval/fidaval-tenant-handler.ts";
import hook29 from "../framework/tenant/fintechsgr/fintechsgr-tenant-handler.ts";
import hook30 from "../framework/tenant/firstcapital/firstcapital-tenant-handler.ts";
import hook31 from "../framework/tenant/galileo/galileo-tenant-handler.ts";
import hook32 from "../framework/tenant/garantizar/garantizar-tenant-handler.ts";
import hook33 from "../framework/tenant/globalmarket/globalmarket-tenant-handler.ts";
import hook34 from "../framework/tenant/inviu/inviu-tenant-handler.ts";
import hook35 from "../framework/tenant/lam/lam-tenant-handler.ts";
import hook36 from "../framework/tenant/matbarofex/matbarofex-tenant-handler.ts";
import hook37 from "../framework/tenant/matchfin/matchfin-tenant-handler.ts";
import hook38 from "../framework/tenant/maxcapital/maxcapital-tenant-handler.ts";
import hook39 from "../framework/tenant/megaqm/megaqm-tenant-handler.ts";
import hook40 from "../framework/tenant/movil/movil-tenant-handler.ts";
import hook41 from "../framework/tenant/neix/neix-tenant-handler.ts";
import hook42 from "../framework/tenant/petrini-valores/petrini-valores-tenant-handler.ts";
import hook43 from "../framework/tenant/portfolio/portfolio-tenant-handler.ts";
import hook44 from "../framework/tenant/ppi/ppi-tenant-handler.ts";
import hook45 from "../framework/tenant/rigsgr/rigsgr-tenant-handler.ts";
import hook46 from "../framework/tenant/rosval/rosval-tenant-handler.ts";
import hook47 from "../framework/tenant/sgr/sgr-tenant-handler.ts";
import hook48 from "../framework/tenant/southerntrust/southerntrust-tenant-handler.ts";
import hook49 from "../framework/tenant/stc/stc-tenant-handler.ts";
import hook50 from "../framework/tenant/stonex/stonex-tenant-handler.ts";
import hook51 from "../framework/tenant/stonex-alyc/stonex-alyc-tenant-handler.ts";
import hook52 from "../framework/tenant/syc/syc-tenant-handler.ts";
import hook53 from "../framework/tenant/syngenta/syngenta-tenant-handler.ts";
import hook54 from "../framework/tenant/trendsgr/trendsgr-tenant-handler.ts";
import hook55 from "../framework/tenant/trinidad-capital/trinidadcapital-tenant-handler.ts";
import hook56 from "../framework/tenant/vinculossgr/vinculossgr-tenant-handler.ts";
import hook57 from "../framework/tenant/winsecurities/winsecurities-tenant-handler.ts";
import hook58 from "../framework/tenant/zofingen/zofingen-tenant-handler.ts";

export default [hook0, hook1, hook2, hook3, hook4, hook5, hook6, hook7, hook8, hook9, hook10, hook11, hook12, hook13, hook14, hook15, hook16, hook17, hook18, hook19, hook20, hook21, hook22, hook23, hook24, hook25, hook26, hook27, hook28, hook29, hook30, hook31, hook32, hook33, hook34, hook35, hook36, hook37, hook38, hook39, hook40, hook41, hook42, hook43, hook44, hook45, hook46, hook47, hook48, hook49, hook50, hook51, hook52, hook53, hook54, hook55, hook56, hook57, hook58];
