/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../../credit/live-form/ui/text-viewer.tsx";
import {
  LiveFieldInputParam,
  LiveFieldRenderer,
} from "../../../credit/live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../../credit/live-form/ui/use-live-after-blur-text-input.tsx";
import {
  NumberFormControl,
  NumberFormControlProps,
} from "../../../credit/ui/form/number-form-control.tsx";
import { numberLiveFieldType } from "./type.ts";

const NumberLiveInput = (fieldInfo: LiveFieldInputParam) => (
  <NumberFormControl
    {
      ...(useLiveAfterBlurTextInput(
        fieldInfo
      ) as unknown as NumberFormControlProps)
      /* FIXME: I had to do this since NumberFormControl uses number, and not string as the type of the input value */
    }
  />
);

export const numberLiveFieldRenderers: LiveFieldRenderer = {
  type: numberLiveFieldType,
  Input: NumberLiveInput,
  Viewer: LiveTextViewer,
};
