/* DON'T EDIT THIS FILE: edit original and run build again */ import { alerter } from "../../../framework/alert/alert.tsx";
import { generateId } from "../../../framework/core/id.ts";
import {
  ListSection,
  ListSectionState,
  useListSectionState,
} from "../../../credit/list-section/list-section.tsx";
import { LiveCell } from "../../../credit/live-form/cell.ts";
import { LiveFormRecordValue } from "../../../credit/live-form/types.ts";
import { LiveFormCreationPopup } from "../../../credit/live-form/ui/creation-popup.tsx";
import {
  LiveFieldGetLiveFormInputFactory,
  LiveFieldRenderer,
  LiveFieldSaver,
  LiveFieldViewer,
  LiveFormWidgets,
} from "../../../credit/live-form/ui/types.ts";
import { useState } from "react";
import { CompactListingRow, ExpandedListingRow } from "./listing-row.tsx";
import { ListingLiveField } from "./live-field.ts";
import { listingLiveFieldType } from "./type.ts";

const getMaxOrderId = (arr: { orderId: number }[]) =>
  arr.reduce((max, { orderId }) => (orderId > max ? orderId : max), -1);

const PopupContent = ({
  getLiveFormInputFactory,
  liveCell,
  saveField,
  Widgets,
  listSectionState,
}: {
  liveCell: LiveCell;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  Widgets: LiveFormWidgets;
  saveField?: LiveFieldSaver;
  listSectionState: ListSectionState;
}) => {
  const liveField = liveCell.getLiveField() as ListingLiveField;
  const saver = saveField
    ? (id: string, value: any) => {
        saveField(liveField.getField() + "." + id, value);
      }
    : null;

  const [dirty, setDirty] = useState(false);
  const confirmAndClose = async () => {
    if (
      !dirty ||
      listSectionState.idToEdit ||
      (await alerter.confirm("¿Estás seguro?"))
    ) {
      listSectionState.dismissPopup();
      setDirty(false);
    }
  };
  return (
    <LiveFormCreationPopup
      title={listSectionState.idToEdit ? "Editar" : "Agregar"}
      buttonLabel={listSectionState.idToEdit ? "Aceptar" : "Agregar"}
      showCancel={!listSectionState.idToEdit}
      liveData={liveCell.getLiveData()}
      getLiveFormInputFactory={getLiveFormInputFactory}
      fieldset={liveField.getFieldset()}
      onCloseClick={confirmAndClose}
      Widgets={Widgets}
      defaultShowErrors={!!listSectionState.idToEdit}
      onRemoveClick={
        listSectionState.idToEdit
          ? async () => {
              if (!saver) {
                throw new Error("missingSaver");
              }
              if (!window.confirm("¿Estás seguro de borralo?")) {
                return;
              }
              listSectionState.dismissPopup();
              await saver(listSectionState.idToEdit as string, undefined);
            }
          : null
      }
      onFieldChange={() => setDirty(true)}
      saver={async (recordValue) => {
        if (!saver) {
          throw new Error("missingSaver");
        }
        const newId = listSectionState.idToEdit ?? generateId();
        const newRecordValue: any = { id: newId, ...recordValue };
        if (liveField.getUseManualSorting()) {
          const value = liveCell.getValue() ?? {};
          newRecordValue.orderId = listSectionState.idToEdit
            ? recordValue.orderId
            : getMaxOrderId(Object.values(value)) + 1;
        }
        await saver(newId, newRecordValue);
        listSectionState.dismissPopup();
      }}
      allowSaveWithErrors={(recordValue: LiveFormRecordValue) =>
        liveField.getAllowSaveWithErrors(recordValue)
      }
      getInitialValue={() =>
        listSectionState.idToEdit
          ? liveCell.getValue()?.[listSectionState.idToEdit]
          : liveField.getFieldset().createDefaultRecord()
      }
    />
  );
};

const getListSectionItems = ({
  liveCell,
  listSectionState,
  getLiveFormInputFactory,
  Widgets,
  saveField,
}: {
  liveCell: LiveCell;
  listSectionState: ListSectionState;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  Widgets: LiveFormWidgets;
  saveField?: LiveFieldSaver;
}) => {
  const value = liveCell.getValue() ?? {};
  const liveField = liveCell.getLiveField() as ListingLiveField;
  const liveData = liveCell.getLiveData();
  const itemsLength = Object.keys(value).length;
  const sortedEntries = liveField.getSortedEntries(Object.entries(value));
  return sortedEntries.map(([id, item], index) => {
    const props = {
      number: liveField.getShowNumbers() ? index + 1 : undefined,
      fieldset: liveField.getFieldset(),
      onEditClick: liveData.viewOnly
        ? undefined
        : () => {
            listSectionState.showEditItem(id);
          },
      getLiveFormInputFactory: getLiveFormInputFactory,
      showErrors: liveCell.getShowErrors(),
      liveData: { ...liveData, recordValue: item },
      Widgets: Widgets,
      collapsed: liveField.isCollapsedRow(item, index, itemsLength),
      collapsedRowPreviewLabel: liveField.getCollapsedRowPreviewLabel(
        item,
        liveData
      ),
      moveItem: liveField.getUseManualSorting()
        ? async (item: { orderId: number }, direction: 1 | -1) => {
            if (!saveField) {
              throw new Error("missingSaveField");
            }
            const newIndex = index + direction;
            if (newIndex < 0 || newIndex >= sortedEntries.length) {
              return;
            }
            const [itemToSwapId, itemToSwap] = sortedEntries[newIndex];
            const field = liveField.getField();
            await saveField(`${field}.${id}.orderId`, itemToSwap.orderId);
            await saveField(`${field}.${itemToSwapId}.orderId`, item.orderId);
          }
        : null,
    };
    const renderTitle = liveField.getRenderTitle();
    if (renderTitle) {
      return (
        <ExpandedListingRow key={id} renderTitle={renderTitle} {...props} />
      );
    } else {
      return <CompactListingRow key={id} {...props} />;
    }
  });
};

const ListingLiveInput = ({
  getLiveFormInputFactory,
  liveCell,
  saveField,
  Widgets,
}: {
  liveCell: LiveCell;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  Widgets: LiveFormWidgets;
  saveField?: LiveFieldSaver;
}) => {
  const liveField = liveCell.getLiveField() as ListingLiveField;
  const liveData = liveCell.getLiveData();
  const listSectionState = useListSectionState();
  return (
    <ListSection
      listSectionState={listSectionState}
      addItemLabel={liveData.viewOnly ? null : liveField.getAddItemLabel()}
      buttonSize="sm"
      items={getListSectionItems({
        getLiveFormInputFactory,
        liveCell,
        listSectionState,
        Widgets,
        saveField,
      })}
      popupSize={liveField.getPopupSize()}
      popupContent={
        <PopupContent
          {...{
            getLiveFormInputFactory,
            liveCell,
            saveField,
            Widgets,
            listSectionState,
          }}
        />
      }
    />
  );
};

const Viewer: LiveFieldViewer = (fieldInfo: {
  liveCell: LiveCell;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  Widgets: LiveFormWidgets;
  saveField?: LiveFieldSaver;
}) => {
  return <ListingLiveInput {...fieldInfo} />;
};

export const listingLiveFieldRenderers: LiveFieldRenderer = {
  type: listingLiveFieldType,
  Input: ListingLiveInput,
  Viewer,
};
