/* DON'T EDIT THIS FILE: edit original and run build again */ import { spaceship } from "../../framework/core/spaceship.ts";
// @ts-expect-error the hook is auto generated
import tenantHandlers from "../../hook/tenant-handler-collect.js";
import { TenantHandler } from "../../framework/tenant-handler-type/tenant-handler.ts";
import { AlycTenantHandler } from "../../framework/tenant-handlers/alyc-tenant-handler.ts";

let collected = false;

const collectTenantHandlers = (): void => {
  if (collected) {
    return;
  }
  collected = true;
  const allTenantHandlers = [...tenantHandlers];
  allTenantHandlers.sort((a, b) => spaceship(a.getLabel(), b.getLabel()));
  setTenantHandlers(allTenantHandlers);
};

let allHandlers: TenantHandler[],
  allHandlersByCode: { [code: string]: TenantHandler },
  allHandlersByEntityId: { [entityId: string]: TenantHandler },
  allTenantCodes: string[],
  allTenantCodesByPathLabel: { [label: string]: string },
  allTenantLabelsByCode: { [code: string]: string },
  allAlycHandlersByCnvRegistryId: { [registryId: string]: AlycTenantHandler };

const setTenantHandlers = (handlers: TenantHandler[]): void => {
  allHandlers = handlers;
  const handlerEntries: [string, TenantHandler][] = allHandlers.map(
    (handler) => [handler.getCode(), handler]
  );
  allTenantLabelsByCode = Object.fromEntries(
    handlerEntries.map(([code, handler]) => [code, handler.getLabel()])
  );
  allHandlersByCode = Object.fromEntries(
    allHandlers.map((handler) => [handler.getCode(), handler])
  );
  allTenantCodes = handlerEntries.map(([code]) => code);
  allTenantCodesByPathLabel = Object.fromEntries(
    allHandlers.map((handler) => [handler.getPathLabel(), handler.getCode()])
  );
  allHandlersByEntityId = Object.fromEntries(
    allHandlers.map((handler) => [handler.getEntityId(), handler])
  );
  const alycHandlersByCnv = allHandlers.filter(
    (handler) =>
      handler instanceof AlycTenantHandler &&
      handler.getCnvRegistryId() !== null
  ) as AlycTenantHandler[];
  allAlycHandlersByCnvRegistryId = Object.fromEntries(
    alycHandlersByCnv.map((handler) => [handler.getCnvRegistryId(), handler])
  );
};

export const getAllTenantHandlers = (): TenantHandler[] => {
  collectTenantHandlers();
  return allHandlers;
};

export class UnknownTenantError extends Error {
  constructor(tenant: string) {
    super("unknownTenant: " + tenant);
  }
}

export const getTenantHandler = (tenant: string): TenantHandler => {
  collectTenantHandlers();
  const obj = allHandlersByCode[tenant];
  if (!obj) {
    throw new UnknownTenantError(tenant);
  }
  return obj;
};

export const getAllTenantCodes = (): string[] => {
  collectTenantHandlers();
  return allTenantCodes;
};

export const getAllTenantLabels = (): { [code: string]: string } => {
  collectTenantHandlers();
  return allTenantLabelsByCode;
};

export const getTenantCodeFromPathLabel = (
  tenantPathLabel: string
): string | null => {
  collectTenantHandlers();
  const code = allTenantCodesByPathLabel[tenantPathLabel];
  if (!code) {
    return null;
  }
  return code;
};

export const getTenantHandlerFromEntityId = (
  entityId: string,
  throwIfMissing: boolean = true
): TenantHandler | null => {
  collectTenantHandlers();
  const handler = allHandlersByEntityId[entityId];
  if (!handler) {
    if (throwIfMissing) {
      throw new Error("unknownTenantEntityId: " + entityId);
    } else {
      return null;
    }
  }
  return handler;
};

export const getAlycTenantHandlerFromCnvRegistryId = (
  cnvRegistryId: string
): AlycTenantHandler | null => {
  collectTenantHandlers();
  const handler = allAlycHandlersByCnvRegistryId[cnvRegistryId];
  if (!handler) {
    return null;
  }
  return handler;
};
