/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { brioOnboardingOptions } from "./brio-onboarding-options.ts";
import { brioTenant } from "./code.ts";
import { brioFullLabel } from "./full-label.ts";
import { brioLabel } from "./label.ts";

class BrioTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_brio.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return brioTenant;
  }

  getPathLabel(): string | null {
    return "brio";
  }

  getLabel(): string {
    return brioLabel;
  }

  getFullLabel(): string {
    return brioFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions {
    return brioOnboardingOptions;
  }

  getLineOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "legajos@briovalores.com";
  }

  getCuit(): string {
    return "30-71453032-8";
  }

  getCnvRegistryId(): string | null {
    return "512";
  }
}

const brioTenantHandler = new BrioTenantHandler();

export default brioTenantHandler;
