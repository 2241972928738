/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { elycaTenant } from "./code.ts";
import { elycaOnboardingOptions } from "./elyca-onboarding-options.ts";
import { elycaFullLabel } from "./full-label.ts";
import { elycaLabel } from "./label.ts";

class ElycaTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_elyca.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return elycaTenant;
  }

  getPathLabel(): string | null {
    return "elyca";
  }

  getLabel(): string {
    return elycaLabel;
  }

  getFullLabel(): string {
    return elycaFullLabel;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return elycaOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "aperturadecuenta@elyca.com.ar";
  }

  getCuit(): string {
    return "30-71584037-1";
  }

  getCnvRegistryId(): string | null {
    return "1148";
  }

  hasSemaphoreSettings(): boolean {
    return false;
  }
}

const elycaTenantHandler = new ElycaTenantHandler();

export default elycaTenantHandler;
