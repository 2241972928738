/* DON'T EDIT THIS FILE: edit original and run build again */ import { WithId } from "../../framework/core/with-id.ts";
import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { reportException } from "../../framework/track/error.ts";
import {
  DocumentBaseData,
  FirestoreDocumentSnapshot,
  FirestoreQuerySnapshot,
} from "./firestore-wrappers-types.ts";
import {
  FirestoreCollection,
  FirestoreDocument,
} from "./firestore-wrappers.ts";
import { Selector } from "./selector-type.ts";

const cleanupDelay = 10000;

/**
 * Since the pipes are created at runtime there's little stack infomation to help debugging
 *  So we use this hack to name functions to keep track of the collection name.
 */
function nameFunction<TArguments extends Array<any>, TReturn>(
  name: string,
  func: (...args: TArguments) => TReturn
): (...args: TArguments) => TReturn {
  return {
    [name]: function (...args: TArguments) {
      return func.apply(this, args);
    },
  }[name];
}

const firestoreColOrDocPipe = <TValue, TSnapshot>(
  selector: Selector<TSnapshot>,
  transformer: (val: TSnapshot) => TValue,
  description: string
): DataPipe<TValue> => {
  let cleanedUp = false;
  // eslint-disable-next-line prefer-const
  let cleaner: (() => void) | null = null;
  let reportedError = false;
  const out = new DataPipe<TValue>(() => {
    cleanedUp = true;
    if (cleaner) {
      setTimeout(cleaner, cleanupDelay);
    }
  }, description);
  const handleNewValue = nameFunction(
    "handleNewValueFor:" + description,
    (val: TSnapshot) => {
      if (reportedError) {
        return;
      }
      // we delay the actual cleanup to make firestore reuse the listener, so this could be triggered after it's not displayed
      if (cleanedUp) {
        return;
      }
      out.setValue(transformer(val));
    }
  );
  cleaner = selector.onSnapshot(handleNewValue, (error) => {
    if (reportedError) {
      return;
    }
    if (cleanedUp) {
      return;
    }
    reportedError = true;
    reportException("firestorePipe:" + description, error);
    out.setError(error);
  });
  return out;
};

const docValToData =
  <TData extends DocumentBaseData, TKey extends string>(
    idProp: TKey | undefined
  ) =>
  (
    val: FirestoreDocumentSnapshot<TData>
  ): WithId<TData, TKey> | TData | undefined => {
    const data = val.data();
    return data && idProp ? { ...data, [idProp]: val.id } : data;
  };

type DocPipeOverloads = {
  <TData extends DocumentBaseData>(doc: FirestoreDocument<TData>): DataPipe<
    TData | undefined
  >;
  <TData extends DocumentBaseData, TKey extends string>(
    doc: FirestoreDocument<TData>,
    idProp: TKey
  ): DataPipe<WithId<TData, TKey> | undefined>;
};
export const docPipe: DocPipeOverloads = <
  TData extends DocumentBaseData,
  TKey extends string
>(
  doc: FirestoreDocument<TData>,
  idProp?: TKey
) => firestoreColOrDocPipe(doc, docValToData(idProp), "doc:" + doc.path);

const colValToData =
  <TData extends DocumentBaseData>() =>
  (val: FirestoreQuerySnapshot<TData>) => {
    const docs: TData[] = [];
    val.forEach((doc) => {
      docs.push(doc.data());
    });
    return docs;
  };

const colValToDataWithId =
  <TData extends Record<string, any>, TKey extends string>(idProp: TKey) =>
  (val: FirestoreQuerySnapshot<TData>) => {
    const docs: WithId<TData, TKey>[] = [];
    val.forEach((doc) => {
      const data = doc.data();
      docs.push({ ...data, [idProp]: doc.id });
    });
    return docs;
  };

type ColPipeOverloads = {
  <TData extends DocumentBaseData>(
    query: FirestoreCollection<TData>,
    idProp: null,
    description: string
  ): DataPipe<TData[]>;
  <TData extends DocumentBaseData, TKey extends string>(
    query: FirestoreCollection<TData>,
    idProp: TKey,
    description: string
  ): DataPipe<WithId<TData, TKey>[]>;
};

export const colPipe: ColPipeOverloads = <
  TItem extends DocumentBaseData,
  IdProp extends string
>(
  query: FirestoreCollection<TItem>,
  idProp: IdProp | undefined,
  description: string
) => {
  return firestoreColOrDocPipe<TItem[], FirestoreQuerySnapshot<TItem>>(
    query,
    idProp ? colValToDataWithId(idProp) : colValToData(),
    "col:" + description
  );
};
