/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  useCurrentTenant,
  useRealmFromUrl,
} from "../../framework/app-state/app-state-context.ts";
import { allowMultiTenantRouting } from "../../framework/routing/tenant-routing.tsx";
import { FlatRouting } from "./flat-routing.front.tsx";
import { MultiTenantRouting } from "./multi-tenant-routing.front.tsx";

export const AppRouting = () => {
  const realm = useRealmFromUrl();
  const currentTenant = useCurrentTenant();
  const canMultiRoute = allowMultiTenantRouting(realm, currentTenant);
  if (canMultiRoute) {
    return <MultiTenantRouting currentTenant={currentTenant} />;
  }
  return <FlatRouting currentTenant={currentTenant} />;
};
