/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserPrivateProfile } from "../../framework/auth-profile/schema.ts";
import { RequiredActionCallback } from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { requiredActionsSettingId } from "../../framework/auth-required-actions-typing/settings-id.ts";
import {
  enforceVerifyEmailId,
  verifyEmailRequiredActionId,
} from "../../framework/auth-verify-email-typing/required-action-type.ts";

export const isNotEmailVerified = ({
  privateProfile,
}: {
  privateProfile?: UserPrivateProfile;
}) => !privateProfile?.[verifyEmailRequiredActionId];

export const shouldEnforceEmailVerify: RequiredActionCallback = (
  { currentTenant },
  settings
) =>
  !!settings?.[requiredActionsSettingId]?.[currentTenant]?.[
    enforceVerifyEmailId
  ];
