/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserProfile } from "../../framework/auth-profile/schema.ts";
import {
  EmailVerificationStatus,
  RequiredActionType,
  SendEmailVerificationMailError,
} from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { useProfile, useRolesMap } from "../../framework/auth-ui/use-account.ts";
import { verifyEmailRequiredActionId } from "../../framework/auth-verify-email-typing/required-action-type.ts";
import { matchfinSupportEmail } from "../../framework/contact-information/matchfin-support.ts";
import { callFirebaseFunction } from "../../framework/firebase/call-firebase-function.ts";
import { getUserRealm } from "../../framework/realm/user-realm.ts";
import {
  LinkInTenant,
  useNavigateInTenant,
} from "../../framework/routing/tenant-routing.tsx";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import { Countdown } from "../../framework/theme/countdown.tsx";
import { FormSection, TitledFormBorder } from "../../framework/theme/form-body.tsx";
import { LoadingButton } from "../../framework/theme/loading-button.tsx";
import { useState } from "react";
import { Button } from "react-bootstrap";
import {
  isNotEmailVerified,
  shouldEnforceEmailVerify,
} from "./required-actions.ts";
import {
  SendEmailVerificationMailCall,
  SendEmailVerificationMailResponse,
  sendEmailVerificationMailCall,
} from "./send-verify-email-call-signature.ts";

enum SendButtonState {
  wait = "wait",
  try = "try",
}

const SendButton = ({
  onSend,
}: {
  onSend: (status: SendEmailVerificationMailResponse) => void;
}): JSX.Element => {
  const [send, setSend] = useState(SendButtonState.try);
  const isWaiting = send === SendButtonState.wait;
  return (
    <div className="d-flex flex-column align-items-end">
      <div className="">
        <LoadingButton
          variant={isWaiting ? "outline-secondary" : "tenant-outline"}
          disabled={isWaiting}
          onClick={async () => {
            setSend(SendButtonState.wait);
            const status =
              await callFirebaseFunction<SendEmailVerificationMailCall>(
                sendEmailVerificationMailCall
              );
            onSend(status);
          }}
        >
          Enviar link
        </LoadingButton>
      </div>
      {isWaiting ? (
        <div className="mt-3 fst-italic">
          Te envíamos el link. Si no llegó, podés reintentar enviarlo en{" "}
          <Countdown time={30} onStop={() => setSend(SendButtonState.try)} />{" "}
          segundos.
        </div>
      ) : null}
    </div>
  );
};

const DefaultMessage = ({
  profile,
  realm,
}: {
  profile: UserProfile;
  realm: string;
}): JSX.Element => (
  <div className="">
    Por seguridad, debemos verificar tu email. Te enviaremos un link de
    verificación a <span className="fw-bold">{profile.email}</span>. Seguilo
    para completar este paso. Recordá checkear también en tu casilla de correo
    no deseado.
    <br />
    Si no tenés acceso a este email, o no está llegando el link, por favor
    comunicate con{" "}
    {realm === matchfinTenant ? (
      <a href={"mailto:" + matchfinSupportEmail}>{matchfinSupportEmail}</a>
    ) : (
      "soporte"
    )}
    . Si este mensaje sigue apareciendo a pesar de haber verificado, por favor
    intentá <LinkInTenant to="/logout">desconectarte</LinkInTenant> y volverte a
    conectar.
  </div>
);

const DefaultState = ({
  profile,
  realm,
  onSend,
}: {
  profile: UserProfile;
  realm: string;
  onSend: (status: SendEmailVerificationMailResponse) => void;
}) => (
  <>
    <DefaultMessage profile={profile} realm={realm} />
    <div className="mt-4 d-flex justify-content-end">
      <SendButton onSend={onSend} />
    </div>
  </>
);

const SuccessState = (): JSX.Element => {
  const navigate = useNavigateInTenant();
  return (
    <>
      <span className="">¡Listo! Verificamos tu email.</span>
      <div className="mt-4 d-flex justify-content-end">
        <Button onClick={() => navigate("/")} variant="tenant">
          Continuar
        </Button>
      </div>
    </>
  );
};

const FailureState = ({
  realm,
  reset,
}: {
  realm: string;
  reset: () => void;
}): JSX.Element => (
  <>
    <span className="">
      Hubo un error. Por favor, reintentá. Si el error persiste, por favor
      comunicate con{" "}
      {realm === matchfinTenant ? (
        <a href={"mailto:" + matchfinSupportEmail}>{matchfinSupportEmail}</a>
      ) : (
        "soporte"
      )}
      .
    </span>
    <div className="mt-4 d-flex justify-content-end fst-italic">
      <Button variant="tenant" onClick={reset}>
        Reintentar
      </Button>
    </div>
  </>
);

const AlreadyVerified = (): JSX.Element => {
  const navigate = useNavigateInTenant();
  return (
    <>
      <span className="">Notamos que ya verificaste el mail. ¡Buenísimo!</span>
      <div className="mt-4 d-flex justify-content-end">
        <Button variant="tenant" onClick={() => navigate("/")}>
          Continuar
        </Button>
      </div>
    </>
  );
};

export const EmailVerificationPrompt = ({
  status,
}: {
  status: EmailVerificationStatus;
}): JSX.Element => {
  const [state, setState] = useState(status ?? EmailVerificationStatus.start);
  const profile = useProfile();
  const roles = useRolesMap();
  const realm = getUserRealm(profile, roles);
  const onSend = (status: SendEmailVerificationMailResponse) => {
    if (!status.ok) {
      if (status.error === SendEmailVerificationMailError.alreadyVerified) {
        setState(EmailVerificationStatus.alreadyVerified);
      } else {
        setState(EmailVerificationStatus.failure);
      }
    }
  };
  return (
    <TitledFormBorder title="Verificación de email">
      <FormSection>
        {state === EmailVerificationStatus.success ? (
          <SuccessState />
        ) : state === EmailVerificationStatus.failure ? (
          <FailureState
            realm={realm}
            reset={() => setState(EmailVerificationStatus.start)}
          />
        ) : state === EmailVerificationStatus.alreadyVerified ? (
          <AlreadyVerified />
        ) : (
          <DefaultState profile={profile} realm={realm} onSend={onSend} />
        )}
      </FormSection>
    </TitledFormBorder>
  );
};

export const verifyEmailRequiredAction: RequiredActionType = {
  id: verifyEmailRequiredActionId,
  label: "Verificación de email.",
  condition: isNotEmailVerified,
  enforce: shouldEnforceEmailVerify,
  Component: EmailVerificationPrompt,
};
